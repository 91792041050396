<template>
  <div class="managerUserOrderView">
    <view-frame :titleObject="titleObject" :buttonObj="buttonObj">
      <template #rightBtn="scope">
        <template v-if="custName && custName.member_actions && custName.member_actions.default_actions">
          <el-button v-show="item.action !== 'view'" v-for="(item, index) in custName.member_actions.default_actions" :key="'default_actions' + index" type="primary" size="mini" @click="handleClick(item, scope.row)">{{item.name}}</el-button>
        </template>
        <template v-if="custName && custName.member_actions && custName.member_actions.custom_actions">
          <el-button v-for="(item, index) in custName.member_actions.custom_actions" :key="'custom_actions' + index" type="primary" size="mini" @click="handleCusClick(item)">{{item.name}}</el-button>
        </template>
      </template>
      <template>
        <el-card>
          <el-row>
            <el-col :span="24">
            <span class="view__text">{{$t('form.order.orderInfo')}}</span>
            <el-card class="animate__animated animate__fadeInDown" shadow="hover">
              <custome-table tableType="object" :custSpan="6" :data="custName.order_information" :dataItem="order_information"></custome-table>
            </el-card>
            </el-col>
          </el-row>
        </el-card>
       <el-row type="flex" class="margintop20">
         <el-card class="widthPercent50 marginRight10" v-if="custName.order_detail">
           <el-row>
            <el-col :span="24">
              <span class="view__text">{{$t('table.order.ordersDetail')}}</span>
              <el-card class="animate__animated animate__fadeInLeft" shadow="hover">
                <div v-for="(citem, cindex) in custName.order_detail.ticket_packages" :key="'package' + cindex">
                  <div v-for="(item, index) in citem.ticketing_payment_items" :key="index" class="customer_detail marginBottom20">
                    <span>{{$i18n.locale === 'zh' ? citem.name_zh : citem.name_en}}</span>
                    <div class="marginBottom4" v-if="item.visit_date">
                      <span class="customer_detail_title">{{$t('form.order.ticketDate')}}</span>
                      <span class="customer_detail_data">{{`${$t('text.symbol')}${item.visit_date}`}}</span>
                    </div>
                    <div class="marginBottom4" v-if="item.visit_time">
                      <span class="customer_detail_title">{{$t('form.order.ticketTime')}}</span>
                      <span class="customer_detail_data">{{`${$t('text.symbol')}${item.visit_time}`}}</span>
                    </div>
                    <div class="customer_detail_number marginBottom4">
                      <span class="customer_detail_title">{{$i18n.locale === 'zh' ? item.ticket_type.name_zh : item.ticket_type.name_en}}</span>
                      <span class="customer_detail_data">{{`S$${item.price}X${item.count}`}}</span>
                    </div>
                  </div>
                </div>
                <div class="customer_detail_amount">
                  <div class="marginBottom4" v-for="(couponItem, couponIndex) in custName.order_detail.coupons" :key="couponIndex">
                     <span class="customer_detail_title">{{couponItem.name}}</span>
                    <span class="customer_detail_data marginLeft20">{{`-S$${couponItem.amount/100}`}}</span>
                  </div>
                  <div class="marginBottom4">
                    <span class="customer_detail_title">{{$t('form.order.totalTaxAmount', { gst: (custName.order_detail.gst_rate * 100).toFixed(0) }) }}</span>
                    <span class="customer_detail_data marginLeft20">{{`S$${custName.order_detail.amount}`}}</span>
                  </div>
                  <div class="marginBottom4">
                    <span class="customer_detail_title">{{$t('form.order.totalPriceExcludingTax')}}</span>
                    <span class="customer_detail_data marginLeft20">{{`S$${custName.order_detail.total_price_excluding_tax}`}}</span>
                  </div>
                  <div class="marginBottom4">
                    <span class="customer_detail_title">{{ $t('form.order.totalTax', { gst: (custName.order_detail.gst_rate * 100).toFixed(0) }) }}</span>
                    <span class="customer_detail_data marginLeft20">{{`S$${custName.order_detail.total_tax}`}}</span>
                  </div>
                </div>
              </el-card>
            </el-col>
          </el-row>
         </el-card>
         <el-card class="widthPercent50 marginLeft10">
           <el-row>
            <el-col :span="24">
              <span class="view__text">{{$t('form.order.contactInfo')}}</span>
              <el-card class="animate__animated animate__fadeInRight" shadow="hover">
                <custome-table tableType="object" :custSpan="12" :data="custName.contact_information" :dataItem="contact_information"></custome-table>
              </el-card>
            </el-col>
            <el-col :span="24" class="margintop20">
              <span class="view__text">{{$t('form.order.custInfo')}}</span>
              <el-card class="animate__animated animate__fadeInRight" shadow="hover">
                <custome-table tableType="object" :custSpan="12" :data="custName.customer_information" :dataItem="customer_information"></custome-table>
              </el-card>
            </el-col>
          </el-row>
         </el-card>
       </el-row>
       <el-card class="margintop20">
          <el-row>
            <el-col :span="24">
              <span class="view__text">{{$t('form.order.items')}}</span>
              <el-card class="animate__animated animate__fadeInUp" shadow="hover">
                 <span class="view__text"></span>
                 <createTable :autoWidth="true" :tableDataItem="tableDataViewItem" :operateVisible="false" :tableData="custName.ticket_copies" :operationColWidth="60" @operate="handleClick" @forwardToPage="handleOrderItemsTableForwardToPage"></createTable>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
        <el-card class="margintop20">
          <el-row>
            <el-col :span="24">
              <span class="view__text">{{$t('form.common.changeHistory')}}</span>
              <el-card class="animate__animated animate__fadeInUp" shadow="hover">
                 <span class="view__text"></span>
                 <createTable :autoWidth="true" :tableDataItem="historyTableItem" :operateVisible="false" :tableData="historyTableData"></createTable>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
      </template>
    </view-frame>
    <dialogEffect v-if="dialogVisible" :todayVisible="true" :dialogVisible.sync="dialogVisible" :data="dialogData" @doNextConfirm="doNextConfirm"></dialogEffect>
    <el-dialog
      :title="$t('form.order.romoveProduct')"
      :visible.sync="dialog.delOderItem.visible"
      width="500px">
      <div class="del-oder-item-dialog-body">
        <div class="heder">
          <el-avatar class="avatar" shape="square" :size="80" fit="cover" :src="dialog.delOderItem.data && dialog.delOderItem.data.image.value"></el-avatar>
          <div class="info-box">
            <div class="title">{{ $t('form.order.productName') }}: {{ dialog.delOderItem.data && dialog.delOderItem.data.shop_item.value.clickables[0].name }}</div>
            <div class="title">{{ $t('form.order.sku') }}: {{ dialog.delOderItem.data && dialog.delOderItem.data.sku.value }}</div>
          </div>
        </div>
        <div class="notice">{{ $t('form.order.removeProducNotice') }}</div>
        <el-form ref="delOderItemDialogForm" :model="dialog.delOderItem.formData" :rules="dialog.delOderItem.formDataRules" label-width="80px" label-position="left">
          <el-form-item :label="$t('form.order.currentCount')">{{ dialog.delOderItem.data && dialog.delOderItem.data.count.value }}</el-form-item>
          <el-form-item :label="$t('form.order.romveCount')" prop="quantity">
            <el-input-number v-model="dialog.delOderItem.formData.quantity" :min="1" :max="dialog.delOderItem.data && dialog.delOderItem.data.count.value"></el-input-number>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="handleDelOderItemDialogCancelClick">{{ $t('button.cancelbig') }}</el-button>
        <el-button type="primary" @click="handleDelOderItemDialogOkClick">{{ $t('button.determine') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import loading from '@/utils/loading.json'
import { getTicketingOrderDetails } from '@/services/order'
import history from '@/common/data/jsonData'
import { getHistoryList } from '@/services/resource'
import createTable from '@/components/createTable'
import viewFrame from '@/components/viewFrame'
import customeTable from '@/components/customeTable'
import dialogEffect from '@/components/dialogEffect'
import jsonData from './jsonData'
import request from '@/utils/request'
import { mixin } from '@/mixins/viewDiolog'

export default {
  components: {
    createTable,
    viewFrame,
    customeTable,
    dialogEffect
  },
  mixins: [mixin],
  data () {
    return {
      validateVisible: false,
      buttonObj: [],
      titleObject: {
        show: true
      },
      custName: {},
      formData: {},
      modifyData: {},
      type: '',
      contact_information: jsonData.contact_information,
      order_information: jsonData.order_information,
      customer_information: jsonData.customer_information,
      historyTableItem: history.historyTableItem,
      tableDataViewItem: jsonData.tableDataViewItem,
      logisticViewItem: [],
      // dialogData: [],
      // batchData: [],
      // dialogVisible: false,
      dialog: {
        delOderItem: {
          visible: false,
          data: null,
          formDataRules: {
            quantity: [
              { required: true, message: this.$t('text.required'), trigger: 'change' }
            ]
          },
          formData: {
            quantity: 1
          }
        }
      },
      historyTableData: []
    }
  },
  beforeRouteLeave (to, from, next) {
    // 导航离开该组件的对应路由时调用
    // 可以访问组件实例 `this`
    this.validateVisible = false
    // const dataOperate = JSON.parse(sessionStorage.getItem('dataOperate'))
    // if (to.name === 'managerProductEdit') {
    //   sessionStorage.setItem('dataOperate', JSON.stringify({ id: dataOperate.id, type: 'edit', obj: dataOperate.obj }))
    // }
    // if (to.name === 'managerProductCopy') {
    //   sessionStorage.setItem('dataOperate', JSON.stringify({ id: dataOperate.id, type: 'copy', obj: dataOperate.obj }))
    // }
    // if (!(to.name === 'managerProductEdit' || to.name === 'managerProductCopy')) {
    //   sessionStorage.setItem('dataOperate', JSON.stringify({ id: undefined, type: undefined, obj: undefined }))
    // }
    //   sessionStorage.setItem('dataOperate', JSON.stringify({ id: undefined, type: undefined, obj: undefined }))
    next()
  },
  created () {
    this.init()
  },
  methods: {
    // 初始化
    async init () {
      this.$store.state.app.loading = this.$loading(loading)
      const res = await getTicketingOrderDetails(this.$route.params.id)
      if (res.status === 200) {
        this.custName = res.data.ticketing_payment
        // Object.assign(this.titleObject, { title: '', data: '', show: false })
        Object.assign(this.titleObject, { title: this.$t('form.order.order') + ' No. ', data: this.custName.order_number || '' })
      }
      this.$store.state.app.loading.close()
      this.getDetail()
    },
    getDetail () {
      if (this.custName && this.custName.ticket_copies && this.custName.ticket_copies.length > 0) {
        Object.keys(this.custName.ticket_copies[0]).forEach(item => {
          const index = this.tableDataViewItem.findIndex(fditem => fditem.prop === item)
          if (index !== -1) this.tableDataViewItem[index].label = this.custName.ticket_copies[0][item].name
        })
      }
      // if (this.custName && this.custName.logistic_information && Object.keys(this.custName.logistic_information).length > 0) {
      //   Object.keys(this.custName.logistic_information).forEach(key => {
      //     this.logisticViewItem.push({
      //       prop: key
      //     })
      //   })
      // }
      //  获取历史操作
      getHistoryList({ item_type: 'TicketingPayment', item_id: this.$route.params.id }).then(res => {
        if (res.status === 200) {
          this.historyTableData = res.data.versions
        }
      })
    },

    async dialogSearch () {
      this.init()
    },
    gobackRouter () {
      this.$router.go(-1)
    },
    // 点击操作按钮
    async handleClick (type, data, extraType) {
      if (extraType === 'del') {
        if (data.count.value > 0) {
          this.dialog.delOderItem.formData.quantity = 1
          this.dialog.delOderItem.data = data
          this.dialog.delOderItem.visible = true
        } else {
          this.$message({
            type: 'warning',
            message: this.$t('form.order.productCountLessThanOne')
          })
        }
      }
    },
    handleDelOderItemDialogCancelClick () {
      this.dialog.delOderItem.visible = false
    },
    handleDelOderItemDialogOkClick () {
      this.$refs.delOderItemDialogForm.validate((valid) => {
        if (valid) {
          const data = this.dialog.delOderItem.data
          const requestOption = data.member_actions.custom_actions[0]
          this.$store.state.app.loading = this.$loading(loading)
          request({
            method: requestOption.method,
            url: `/cms/${requestOption.path}`,
            data: {
              [requestOption.extra_params[0].key]: this.dialog.delOderItem.formData.quantity
            }
          }).then(res => {
            this.$message({
              type: 'success',
              message: this.$t('message.operateSuccess')
            })
            this.dialog.delOderItem.visible = false
            this.init()
          }).catch(() => this.$store.state.app.loading.close())
        }
      })
    },
    forwardToPage (data) {
      if (data.model_name && data.model_name === 'user' && data.id) {
        this.$router.push({
          name: 'memberDetail',
          params: {
            id: data.id
          }
        })
      }
    },
    handleOrderItemsTableForwardToPage (data, name) {
      if (name === 'ticket_package') {
        this.$router.push({
          name: 'zooTicketsDetail',
          params: {
            id: data.id
          }
        })
      } else if (name === 'ticket_type') {
        this.$router.push({
          name: 'zooTicketsTypeDetail',
          params: {
            id: data.id
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scope>
.managerUserOrderView {
  .customer_detail_title {
    font-size: 12px;
    margin-bottom: 4px;
    font-weight: 700;
  }
  .customer_detail_data {
    font-size: 14px;
    color: #40586F;
  }
  .customer_detail {
    display: flex;
    flex-direction: column;
    > span {
      font-size: 16px;
      font-weight: 700;
    }
  }
  .customer_detail_number {
    display: flex;
    justify-content: space-between;
  }
  .customer_detail_amount {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .del-oder-item-dialog-body {
    .heder {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      .avatar {
        flex-shrink: 0;
      }
    }
    .info-box {
      margin-left: 12px;
      margin-bottom: -12px;
      .title {
        margin-bottom: 12px;
      }
    }
    .notice {
      margin-bottom: 12px;
    }
  }
}
</style>
