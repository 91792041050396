// import i18n from '@/i18n/index'

export default {
  tableDataItem: [{
    prop: 'user_nickname',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'shop',
    label: '',
    Object: 'value',
    width: '150'
  }, {
    prop: 'order_detail',
    label: '',
    Object: 'value',
    width: '430'
  }, {
    prop: 'delivery_detail',
    label: '',
    Object: 'value',
    width: '430'
  }, {
    prop: 'status',
    back: true,
    label: '',
    Object: 'value',
    width: '120'
  }],
  order_information: [{
    prop: 'status',
    span: 4,
    back: true
  }, {
    prop: 'sub_status',
    back: true,
    span: 4
  }, {
    prop: 'third_party_ref_no',
    span: 4
  }, {
    prop: 'payment_channel',
    span: 4
  }, {
    prop: 'created_at',
    span: 4
  }, {
    prop: 'paid_at',
    span: 4
  }],
  contact_information: [{
    prop: 'contact_name'
  }, {
    prop: 'contact_number'
  }, {
    prop: 'contact_dob'
  }, {
    prop: 'country_of_residence'
  }, {
    prop: 'email'
  }],
  customer_information: [{
    prop: 'customer_name'
  }, {
    prop: 'contact_number'
  }, {
    prop: 'customer_id'
  }, {
    prop: 'nickname'
  }, {
    prop: 'email'
  }],
  tableDataViewItem: [{
    prop: 'ticket_package',
    label: '',
    Object: 'value'
  }, {
    prop: 'ticket_type',
    label: '',
    Object: 'value'
    // width: '40'
  }, {
    prop: 'code',
    label: '',
    width: '100',
    Object: 'value'
  }, {
    prop: 'format',
    label: '',
    Object: 'value'
    // width: '100'
  }, {
    prop: 'quantity',
    label: '',
    Object: 'value'
    // width: '100'
  }, {
    prop: 'status',
    label: '',
    Object: 'value'
    // width: '40'
  }]
}
